import React from 'react';
import Image from 'gatsby-image';
import Layout from '../../components/Layout';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Favicon from '../../images/favicon.ico';
import CountUp from 'react-countup';
import { Link } from 'gatsby';
const BlindageMethod = [
  {
    name: 'Blindage à plat',

    advantages: (
      <ul className="list-disc ml-8 text-left">
        <li>
          Le blindage à plat est généralement discret et ne modifie pas
          l'apparence extérieure de la porte.
        </li>
        <li>moins coûteux que d'autres méthodes de blindage.</li>
      </ul>
    ),
    disadvantages: (
      <ul className="list-disc ml-8 text-left">
        <li>
          le blindage à plat ne protège que la face extérieure de la porte,
          laissant les côtés exposés
        </li>
      </ul>
    ),
  },
  {
    name: 'Blindage à fourreau',

    advantages: (
      <ul className="list-disc ml-8 text-left">
        <li>
          Il protège contre les tentatives de perçage ou de découpe de la porte.
        </li>
        <li>
          Il peut améliorer l'isolation thermique et phonique de la porte.
        </li>
      </ul>
    ),
    disadvantages: (
      <ul className="list-disc ml-8 text-left">
        <li>Coût élevé</li>
        <li>
          {' '}
          L'installation d'un blindage à fourreau peut être plus complexe et
          nécessiter des compétences professionnelles.
        </li>
      </ul>
    ),
  },
  {
    name: 'Bloc-porte',

    advantages: (
      <ul className="list-disc ml-8 text-left">
        <li>
          Les blocs-portes sont conçus pour être résistants et offrent une
          intégrité structurelle élevée, ce qui les rend difficiles à forcer. w
        </li>
      </ul>
    ),
    disadvantages: (
      <ul className="list-disc ml-8 text-left">
        <li>
          {' '}
          Les blocs-portes de haute sécurité peuvent être coûteux à l'achat et à
          l'installation.
        </li>
      </ul>
    ),
  },
];

function BlindagePorte({ data }) {
  const { SuperSerrurierVan, PorteBlinde, GenevePorte } = data;
  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={Favicon} />
        <meta charSet="utf-8" />
        <title>
          Service de blindage de portes | Service rapide et professionnel
        </title>
        <meta
          name="description"
          content="Blindage de portes résidentielles et commerciales. Notre équipe d'experts installe et répare les blindages de portes avec professionnalisme. Obtenez rapidement un devis personnalisé ou contactez notre service clientèle au +4179 130 47 68."
        />
      </Helmet>
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <main className="flex justify-between gap-4 lg:gap-8 mt-16 sm:mt-24 flex-col lg:flex-row">
          <div className="w-full lg:w-1/2">
            <h1 className="text-textcolor text-2xl tracking-tight font-extrabold sm:leading-none lg:text-4xl xl:text-5xl">
              Protection et Sécurité de votre Propriété - Blindage de Portes
              Professionnel
            </h1>
            <div className="w-full lg:hidden">
              <Image
                fluid={SuperSerrurierVan.childImageSharp.fluid}
                alt="Voiture de service utulisé pour les intervention de blindage de porte"
              />
            </div>
            <p className="mt-4 text-lg text-gray-600">
              La sécurité de votre domicile ou de votre entreprise est notre
              priorité. Lorsque vous avez besoin de renforcer la protection de
              vos portes, faites-nous confiance pour un service de blindage
              professionnel et fiable. Que ce soit pour le blindage d'une porte
              existante ou en installer une nouvelle, notre équipe possède
              l'expertise nécessaire pour analyser vos besoins et vous proposer
              la meilleure solution. Nous travaillons avec les matériaux les
              plus résistants afin d'offrir une protection optimale contre
              l'effraction et le vandalisme.
            </p>
            <a
              id="call-button"
              href="tel:0791304768"
              className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
            >
              <span className="px-3 py-2 text-white text-lg  lg:text-xl xl:text-2xl  leading-5  tracking-wide bg-primarycolor rounded-full">
                Appelez-nous
              </span>
              <span className="ml-3 text-lg  lg:text-xl xl:text-2xl font-semibold leading-5  ">
                079 130 47 68
              </span>
              <svg
                className="ml-2 w-6 h-6 "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <div className="w-full hidden lg:block lg:w-1/2">
            <Image
              fluid={SuperSerrurierVan.childImageSharp.fluid}
              alt="Voiture de service utulisé pour les intervention de blindage de portes"
            />
          </div>
        </main>
        <div className="w-screen absolute left-0 mt-24 py-8 lg:py-14 h-fit bg-primarycolor">
          <dl className="text-center sm:max-w-7xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                Clients par an
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp start={0} end={3000} duration={2.75} prefix="+ " />
              </dd>
            </div>{' '}
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                Temps d'intervention
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp
                  end={20}
                  start={60}
                  duration={2.75}
                  suffix=" Minutes"
                />
              </dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                De satisfaction
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp start={0} end={98} duration={2.75} suffix="%" />
              </dd>
            </div>
          </dl>
        </div>
        <div className="mt-128 lg:mt-96 rounded-md relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <Image
              fluid={GenevePorte.childImageSharp.fluid}
              alt="Les types de serrures"
              className={'hidden lg:block'}
            />
          </div>{' '}
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className="inline text-primarycolor">
                Les différentes méthodes de blindage de portes
              </h2>
              <Image
                fluid={GenevePorte.childImageSharp.fluid}
                alt="Les types de serrures"
                className={'my-8 lg:hidden'}
              />
            </div>
            <ul className="mt-4 lg:mt-8 list-disc space-y-2 text-lg leading-6 font-medium text-gray-600">
              Pour garantir la sécurité de votre domicile ou de votre
              entreprise, le choix du blindage de porte approprié est essentiel.
              Selon vos besoins en matière de sécurité, voici quelques méthodes
              à considérer :
              <br />
              <li className="text-lg leading-6 font-medium text-gray-600">
                <b>Un blindage à plat :</b> consiste à installer une plaque
                d’acier sur la face intérieure de votre porte en bois
              </li>
              <li className="text-lg leading-6 font-medium text-gray-600">
                <b>Un blindage fourreau :</b> est un blindage à plat qui en plus
                a des retours de chaque coté de la porte et sur la face
                extérieure de la porte.
              </li>
              <li className="text-lg leading-6 font-medium text-gray-600">
                <b>Bloc-porte :</b> Votre porte a été remplacée par un
                bloc-porte blindé, comprenant à la fois l'encadrement et la
                porte en un seul élément.
              </li>
            </ul>

            <div className="mt-8 sm:flex">
              <div className="block rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light"
                >
                  Appelez-nous
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 lg:mt-32 rounded-md relative lg:grid overflow-x-scroll lg:overflow-x-hidden lg:items-center">
          <h2 className="text-primarycolor text-center text-2xl tracking-tight font-extrabold sm:leading-none lg:text-4xl xl:text-5xl">
            Comparatif entre les différentes méthodes de blindage de portes
          </h2>
          <table id="prices-table" className="table-auto mt-8">
            <thead>
              <tr>
                <th className="text-white w-52 border-2 border-gray-900 bg-primarycolor-light py-2">
                  Type de blindage
                </th>

                <th className="text-white  border-2 border-gray-900 bg-primarycolor-light py-2">
                  Avantages
                </th>
                <th className="text-white border-2 border-gray-900 bg-primarycolor-light py-2">
                  Inconvénient
                </th>
              </tr>
            </thead>
            <tbody>
              {BlindageMethod.map((e) => {
                return (
                  <tr>
                    <td className="text-textcolor text-center border-2 border-gray-900 py-2">
                      {e.name}
                    </td>

                    <td className="text-textcolor border-2 text-center border-gray-900  py-2">
                      {e.advantages}
                    </td>
                    <td className="text-textcolor border-2 text-center border-gray-900  py-2">
                      {e.disadvantages}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="mt-12 lg:mt-32 rounded-md relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className="inline text-primarycolor">
                Tarifs et Prix pour un blindage de porte
              </h2>
              <Image
                fluid={PorteBlinde.childImageSharp.fluid}
                alt="un serrurier a geneve"
                className={'my-4 lg:hidden rounded-lg'}
              />
            </div>
            <div className="mt-4 lg:mt-8 list-disc space-y-4">
              <p className="text-lg leading-6 font-medium text-gray-600">
                En ce qui concerne la tarification, les frais associés à un
                blindage de porte par un serrurier agréé sont influencés par
                divers éléments, dont la technique de blindage, type de serrure
                (serrures de 3 à 9 points), et du coût de la main-d’œuvre.
              </p>
            </div>

            <div className="mt-8 sm:flex">
              <div className="block rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light"
                >
                  Appelez-nous
                </a>
              </div>
            </div>
          </div>
          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <Image
              fluid={PorteBlinde.childImageSharp.fluid}
              alt="Un serrurier a geneve"
              className={'hidden lg:block rounded-lg'}
            />
          </div>{' '}
        </div>
        <div className="mt-12 lg:mt-32">
          <h2 className="text-primarycolor text-2xl font-extrabold text-center tracking-tight sm:text-5xl">
            Nos Zones d’interventions
          </h2>
          <div className="pt-12 grid grid-cols-2 content-center gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-5">
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/valais"
                className="zone-area duration-150 text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.valais.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Valais"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Valais
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/serrurier-geneve"
                className="zone-area duration-150 flex flex-col items-center text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.geneve.childImageSharp.fixed}
                  className="h-32 max-w-32 max-h-32 mx-auto conton"
                  alt="Genève"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Genève
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/neuchatel"
                className="zone-area duration-150 flex flex-col items-center text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.neuchatel.childImageSharp.fixed}
                  className="h-32 max-w-32  max-h-32 conton"
                  alt="Neuchatel"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Neuchatel
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8 text-gray-600 hover:text-primarycolor">
              <Link
                to="/vaud"
                className="zone-area flex flex-col items-center duration-150"
              >
                <Image
                  fixed={data.vaud.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Vaud"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Vaud
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-14 text-gray-600 hover:text-primarycolor">
              <Link
                to="/fribourg"
                className="zone-area flex flex-col items-center duration-150"
              >
                <Image
                  fixed={data.fribourg.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Fribourg"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Fribourg
                </h3>
              </Link>
            </div>
          </div>
          <div className="mx-auto flex justify-center pb-12">
            <a
              id="call-button"
              href="tel:0791304768"
              className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
            >
              <span className="px-3 py-2 text-white text-lg  lg:text-xl xl:text-2xl  leading-5  tracking-wide bg-primarycolor rounded-full">
                Appelez-nous
              </span>
              <span className="  ml-3 text-lg  lg:text-xl xl:text-2xl font-semibold leading-5  ">
                079 130 47 68
              </span>
              <svg
                className="ml-2 w-6 h-6 "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BlindagePorte;

export const query = graphql`
  query blindage_porte_page {
    SuperSerrurierVan: file(relativePath: { eq: "SuperSerrurierVan.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    PorteBlinde: file(relativePath: { eq: "porte_blindé.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    GenevePorte: file(relativePath: { eq: "porte-blindé-geneve.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    TypeDeSerrure: file(relativePath: { eq: "type_de_serrure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    fribourg: file(relativePath: { eq: "Fribourg.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    geneve: file(relativePath: { eq: "Geneve.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    neuchatel: file(relativePath: { eq: "neuchatel.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    valais: file(relativePath: { eq: "Valais.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    vaud: file(relativePath: { eq: "Vaud.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
